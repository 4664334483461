import React from 'react';
import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <p style={{fontSize: 16}}>Under construction.....</p>
        <h1>Merle Kowalik</h1>
        <p style={{fontSize: 12}}>Or I just can't be bothered to start it yet!</p>
      </header>
    </div>
  );
}

export default App;
